<template>
    <div class="MyTable">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "MyTable"
    }
</script>

<style lang="scss" scoped>
    .MyTable {
        margin: 0 16px;
        display: flex;
        flex-direction: column;
    }
</style>